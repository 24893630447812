import { makeAutoObservable } from "mobx";

class CanvasState {
    canvas = null
    username = ""
    constructor() {
        makeAutoObservable(this)
    }

    setUsername(username){
        this.username = username
    }

    setCanvas(canvas) {
        this.canvas = canvas
    }

}

export default new CanvasState()