import "./styles.css";
import { createRoot } from "react-dom/client";
import Canvas from "./components/Canvas";
import React from "react";


function App() {
  console.log('@@@')
  return (
    <main>
      <Canvas/>
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
